export default [
  {
    header: 'About Us',
    items: [
      {
        path: '/',
        label: 'Support Center',
      },
      {
        path: '/',
        label: 'Customer Support',
      },
      {
        path: '/',
        label: 'About Us',
      },
      {
        path: '/',
        label: 'Copyright',
      },
      {
        path: '/',
        label: 'Popular Campaign',
      },
    ],
  },
  {
    header: 'Our Information',
    items: [
      {
        path: '/',
        label: 'Return Policy ',
      },
      {
        path: '/',
        label: 'Privacy Policy',
      },
      {
        path: '/',
        label: 'Terms & Conditions',
      },
      {
        path: '/',
        label: 'Site Map',
      },
      {
        path: '/',
        label: 'Store Hours',
      },
    ],
  },
  {
    header: 'My Account',
    items: [
      {
        path: '/',
        label: 'Press inquiries',
      },
      {
        path: '/',
        label: 'Social media ',
      },
      {
        path: '/',
        label: 'directories',
      },
      {
        path: '/',
        label: 'Images & B-roll',
      },
      {
        path: '/',
        label: 'Permissions',
      },
    ],
  },
  {
    header: 'Policy',
    items: [
      {
        path: '/',
        label: 'Application security',
      },
      {
        path: '/',
        label: 'Software principles',
      },
      {
        path: '/',
        label: 'Unwanted software policy',
      },
      {
        path: '/',
        label: 'Responsible supply chain',
      },
    ],
  },
];
